/* eslint-disable */
// Ids are just dummy GUIDS from https://guidgenerator.com/
export const help_content_components = [
    {
        id: '1cadc0df-a938-4901-b7a1-db2a5b88ea0f',
        title: "Home",
        filename: "logs_home_page_help",
    },
    {
        id: '43ab0b3e-0c42-41dd-bb9b-2a0d57c92f33',
        title: "Division Page",
        filename: "division_page_help",
    },
    {
        id: '14dbe545-c209-43b2-a981-4eed0a422cac',
        title: "Log Editor",
        filename: "log_editor_page_help",
    },
    {
        id: 'ffd7b0f1-3dbe-4050-85c0-898adefecb0d',
        title: "Search",
        filename: "search_page_help",
    },
]